// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

//---- jQuery(UI) ----
import './src/jquery'
import 'jquery-ui/dist/jquery-ui'

//---- JavintoAppPages
require("./packs/content/content_assets")
require("./packs/content/content_pages")

//---- Faasen-advies.nl ----
require("./packs/bootstrap_activation");


/**
/* Skip ActionText in favour of TinyMCE, but keep it installed 
/* import "trix"
/* import "@rails/actiontext"
**/


/* ActionText changes. Gebruik <p> ipv <div> en <h2> ipv <h1>*/
// Trix.config.blockAttributes.default= {
//     tagName: "p",
//     parse: false
// }

// Trix.config.blockAttributes.heading1 = {
//     tagName: "h2",
//     terminal: true,
//     breakOnReturn: true,
//     group: false
// }